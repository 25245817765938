import React from 'react'
import { Link } from "gatsby"
import Layout from "../templates/Layout"
import { Hero, Container, Row, } from "@edinburghairport/runway"
import MultipleItems from "../components/SimpleSlider"
import '../scss/main.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class RootIndex extends React.Component {

  render() {

    const content = {
      background: {
        file: {
          url: "https://edinburghairport.s3-eu-west-1.amazonaws.com/files/acp2019/home_bg.png"
        }
      },
      heading: "Redefining our approach",
      subtitle: "On 1 July 2019 we launched an initiative to change the way airspace is used at Edinburgh Airport.  By harnessing advanced technology, we aim to modernise the airport and make our airspace more efficient. This work will follow the CAP1616 process set by the CAA, the UK’s aviation regulator.",
      link: {
        slug: "https://airspacechange.caa.co.uk/PublicProposalArea?pID=163"
      }
    };

    return (
      <Layout>
        <Hero content={content} />
        <div className="section section__carousel">

          <Container>

            <Row>
              <div className="col-12">
                <h3>EDINBURGH AIRSPACE CHANGE PROGRAMME PROGRESS</h3>
              </div>

            </Row>
            <MultipleItems />

          </Container>
        </div>
        <div className="section section__body">
          <Container>

            <Row>
            <div className="col-sm-0 col-md-3">
              </div>
              <div className="col-md-6">
                <div className="wrapper">
                  <h3>PREVIOUS CONSULTATION MATERIAL</h3>
                  <p>We’ve run three public consultations to support our previous plans to change the use of airspace at Edinburgh Airport. Click below to read about these consultations.</p>
                </div>
                <Link to="/previous-consultation-material">read more...</Link>
              </div>
              <div className="col-sm-0 col-md-2">
              </div>
              {/* <div className="col-md-5">
                <div className="wrapper">
                  <h3>UPCOMING MEETINGS</h3>
                  <p>Air your views at one of our upcoming meetings in an area near you</p>
                </div>
                <Link to="/upcoming-meetings">read more...</Link>
              </div> */}
            </Row>

          </Container>
        </div>
      </Layout>
    )
  }
}

export default RootIndex