let sliderContent = {
    step1a: "<h4>Step 1a : Assess Requirements - This step was completed on 1 Jun 2019</h4><p>During the Assess Requirement Step, the change sponsor prepares a Statement of Need setting out what airspace issue it is seeking to address. Having reviewed the Statement of Need, the CAA meets with the change sponsor to agree whether an airspace change is a relevant option to consider, and to have a first discussion about the appropriate scale of the airspace change process</p>",


step1b: "<h4>Step 1b : Design Principles - This step has been started</h4><p>During the Design Principles step, the sponsor develops the principles which will underpin their proposed options for the change. The design principles encompass the safety, environmental and operational criteria and strategic policy objectives that the change sponsor aims for in developing the airspace change proposal. They are developed through engagement with stakeholders and form a qualitative structure against which design options can be evaluated. Early engagement with stakeholders, optionally facilitated by a third party, may help to avoid disagreement later in the process.</p>",

define : "<h4>Define Gateway - This step has not been started</h4><p>At the Define Gateway, the CAA reviews and signs-off the documentation relating to Stage 1.</p>",

step2a: "<h4>Step 2a : Develop and Assess - This step has not been started</h4><p>During the Develop & Assess Step, the change sponsor develops one or more options that address the Statement of Need and align with the defined design principles.</p>",

step2b: "<h4>Step 2b : Options Appraisal - This step has not been started</h4><p>During the Options Appraisal Step, each option, even if there is only one, is assessed to understand the impact, both positive and negative. This is the first of three options appraisals developed during the process.</p>",

develop: "<h4>Develop & Assess Gateway - This step has not been started</h4><p>At the Develop and Assess Gateway, the CAA reviews and signs-off the documentation relating to Stage 2.</p>",

step3a: "<h4>Step 3a : Consultation Preparation - This step has not been started</h4><p>During the Consultation Preparation Step, the sponsor plans its stakeholder consultation and engagement, and prepares consultation documents, including the second-phase Full options appraisal with more rigorous evidence for its chosen option(s).</p>",

step3b: "<h4>Step 3b : Consultation Validation - This step has not been started</h4><p>During the Consultation Validation Step, the CAA reviews and where appropriate approves the consultation strategy. This is to ensure the strategy is comprehensive, the materials clear and appropriate, and the questions unbiased.</p>",

consult: "<h4>Consult Gateway - This step has not been started</h4><p>At the Consult Gateway, the CAA reviews and signs-off the documentation relating to the sponsor's consultation.</p>",

step3c: "<h4>Step 3c : Commence Consultation - This step has not been started</h4><p>At the Commence Consultation Step, the change sponsor implements its consultation strategy and launches the consultation.</p>",

step3d: "<h4>Step 3d : Collate & Review Responses - This step has not been started</h4><p>During the Collate & Review Responses Step, consultation responses are collated, reviewed and categorised.</p>",

step4a: "<h4>Step 4a : Update Design - This step has not been started</h4><p>During the Update Design Step, the change sponsor considers the consultation responses, identifies any consequent design changes, and updates the options appraisal, submitting these to the CAA for review.</p>",

step4b: "<h4>Step 4b : Submit Proposal to CAA - This step has not been started</h4><p>During the Submit Proposal Step, the change sponsor prepares the formal airspace change proposal using a template and submits it to the CAA</p>",

step5a: "<h4>Step 5a : CAA Assessment - This step has not been started</h4><p>During the CAA Assessment Step, the CAA reviews and assesses the airspace change proposal, and for Level 1 changes offers a Public Evidence Session. The CAA may request minor changes to the proposal. The prepares assessment papers to inform and provide guidance to the airspace change decision-maker.</p>",

step5b: "<h4>Step 5b : CAA Decision - This step has not been started</h4><p>During the CAA Decision Step, the CAA decides whether to grant or reject the airspace change proposal. For Level 1 changes, the CAA will normally seek views on a draft of the decision, or the Secretary of State may ‘call-in’ the proposal</p>",

decide: "<h4>Decide Gateway - This step has not been started</h4><p>At the Decide Gateway, the CAA decides whether or not to approve the Airspace Change Proposal.</p>",

step6: "<h4>Step 6 : Implement - This step has not been started</h4><p>During the Implement Step, the change sponsor implements the approved change, working with air navigation service providers as necessary</p>",

step7: "<h4>Step 7 : Post-Implementation Review - This step has not been started</h4><p>At the Post-Implementation Review Step, the CAA reviews how the airspace change has performed, including whether anticipated impacts and benefits in the original proposal and decision have been delivered.</p>"
};



export default sliderContent