import React, { Component } from "react";
import Slider from "react-slick";
import sliderContent from "./slider-content.js";

export default class MultipleItems extends Component {
  constructor(props) {
    super(props)
    this.state = {
      displayText: sliderContent.step1a,
      activeIndex: '',
    }
    this.showText = this.showText.bind(this);
  }

  showText(e, number, index) {
    e.preventDefault();


    this.setState({
      displayText: number,
      activeIndex: index
    })
  }

  render() {
    const settings = {
      dots: true,
      infinite: false,
      autoplay: false,
      arrows: true,
      slidesToShow: 6,
      slidesToScroll: 6,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            dots: true
          }
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            dots: true
          }
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            dots: true
          }
        },

      ]

    };

  

    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: this.state.displayText }} />
        <div className="slider">
          <Slider {...settings}>

            <div>
              <button onClick={(e) => { this.showText(e, sliderContent.step1a, 0) }}>
                <div className={this.state.activeIndex !== 0 ? 'slider__circle' : ' slider__circle--active'}>
                  <div className={this.state.activeIndex !== 0 ? 'slider__circle__inner' : 'slider__circle--active__inner'}>
                    <p>Step 1a</p>
                  </div>
                </div>
              </button>
            </div>
            <div>
              <button onClick={(e) => { this.showText(e, sliderContent.step1b, 1) }}>
                <div className={this.state.activeIndex !== 1 ? 'slider__circle' : ' slider__circle--active'}>
                  <div className={this.state.activeIndex !== 1 ? 'slider__circle__inner' : 'slider__circle--active__inner'}>
                    <p>Step 1b</p>
                  </div>
                </div>
              </button>
            </div>
            <div>
              <button onClick={(e) => { this.showText(e, sliderContent.define, 2) }}>
                <div className={this.state.activeIndex !== 2 ? 'slider__circle' : ' slider__circle--active'}>
                  <div className={this.state.activeIndex !== 2 ? 'slider__circle__inner' : 'slider__circle--active__inner'}>
                    <p>Define Gateway</p>
                  </div>
                </div>
              </button>
            </div>
            <div>
              <button onClick={(e) => { this.showText(e, sliderContent.step2a, 3) }}>
                <div className={this.state.activeIndex !== 3 ? 'slider__circle' : ' slider__circle--active'}>
                  <div className={this.state.activeIndex !== 3 ? 'slider__circle__inner' : 'slider__circle--active__inner'}>
                    <p>Step 2a</p>
                  </div>
                </div>
              </button>
            </div>
            <div>
              <button onClick={(e) => { this.showText(e, sliderContent.step2b, 4) }}>
                <div className={this.state.activeIndex !== 4 ? 'slider__circle' : ' slider__circle--active'}>
                  <div className={this.state.activeIndex !== 4 ? 'slider__circle__inner' : 'slider__circle--active__inner'}>
                    <p>Step 2b</p>
                  </div>
                </div>
              </button>
            </div>

            <div>
              <button onClick={(e) => { this.showText(e, sliderContent.develop, 5) }}>
              <div className={this.state.activeIndex !== 5 ? 'slider__circle' : ' slider__circle--active'}>
                  <div className={this.state.activeIndex !== 5 ? 'slider__circle__inner' : 'slider__circle--active__inner'}>
                    <p>Develop and Assess Gateway</p>
                  </div>
                </div>
              </button>
            </div>

            <div>
              <button onClick={(e) => { this.showText(e, sliderContent.step3a, 6) }}>
                <div className={this.state.activeIndex !== 6 ? 'slider__circle' : ' slider__circle--active'}>
                  <div className={this.state.activeIndex !== 6 ? 'slider__circle__inner' : 'slider__circle--active__inner'}>
                    <p>Step 3 a</p>
                  </div>
                </div>
              </button>
            </div>
            <div>
              <button onClick={(e) => { this.showText(e, sliderContent.step3b, 7) }}>
                <div className={this.state.activeIndex !== 7 ? 'slider__circle' : ' slider__circle--active'}>
                  <div className={this.state.activeIndex !== 7 ? 'slider__circle__inner' : 'slider__circle--active__inner'}>
                    <p>Step 3 b</p>
                  </div>
                </div>
              </button>
            </div>
            <div>
              <button onClick={(e) => { this.showText(e, sliderContent.consult, 8) }}>
                <div className={this.state.activeIndex !== 8 ? 'slider__circle' : ' slider__circle--active'}>
                  <div className={this.state.activeIndex !== 8 ? 'slider__circle__inner' : 'slider__circle--active__inner'}>
                    <p>Consult Gateway</p>
                  </div>
                </div>
              </button>
            </div>
            <div>
              <button onClick={(e) => { this.showText(e, sliderContent.step3c, 9) }}>
                <div className={this.state.activeIndex !== 9 ? 'slider__circle' : ' slider__circle--active'}>
                  <div className={this.state.activeIndex !== 9 ? 'slider__circle__inner' : 'slider__circle--active__inner'}>
                    <p>Step 3 c</p>
                  </div>
                </div>
              </button>
            </div>
            <div>
              <button onClick={(e) => { this.showText(e, sliderContent.step3d, 10) }}>
                <div className={this.state.activeIndex !== 10 ? 'slider__circle' : ' slider__circle--active'}>
                  <div className={this.state.activeIndex !== 10 ? 'slider__circle__inner' : 'slider__circle--active__inner'}>
                    <p>Step 3 d</p>
                  </div>
                </div>
              </button>
            </div>
            <div>
              <button onClick={(e) => { this.showText(e, sliderContent.step4a, 11) }}>
                <div className={this.state.activeIndex !== 11 ? 'slider__circle' : ' slider__circle--active'}>
                  <div className={this.state.activeIndex !== 11 ? 'slider__circle__inner' : 'slider__circle--active__inner'}>
                    <p>Step 4 a</p>
                  </div>
                </div>
              </button>
            </div>
            <div>
              <button onClick={(e) => { this.showText(e, sliderContent.step4b, 12) }}>
                <div className={this.state.activeIndex !== 12 ? 'slider__circle' : ' slider__circle--active'}>
                  <div className={this.state.activeIndex !== 12 ? 'slider__circle__inner' : 'slider__circle--active__inner'}>
                    <p>Step 4 b</p>
                  </div>
                </div>
              </button>
            </div>
            <div>
              <button onClick={(e) => { this.showText(e, sliderContent.step5a, 13) }}>
                <div className={this.state.activeIndex !== 13 ? 'slider__circle' : ' slider__circle--active'}>
                  <div className={this.state.activeIndex !== 13 ? 'slider__circle__inner' : 'slider__circle--active__inner'}>
                    <p>Step 5 a</p>
                  </div>
                </div>
              </button>
            </div>
            <div>
              <button onClick={(e) => { this.showText(e, sliderContent.step5b, 14) }}>
                <div className={this.state.activeIndex !== 14 ? 'slider__circle' : ' slider__circle--active'}>
                  <div className={this.state.activeIndex !== 14 ? 'slider__circle__inner' : 'slider__circle--active__inner'}>
                    <p>Step 5b</p>
                  </div>
                </div>
              </button>
            </div>
            <div>
              <button onClick={(e) => { this.showText(e, sliderContent.decide, 15) }}>
                <div className={this.state.activeIndex !== 15 ? 'slider__circle' : ' slider__circle--active'}>
                  <div className={this.state.activeIndex !== 15 ? 'slider__circle__inner' : 'slider__circle--active__inner'}>
                    <p>Decide Gateway</p>
                  </div>
                </div>
              </button>
            </div>
            <div>
              <button onClick={(e) => { this.showText(e, sliderContent.step6, 16) }}>
                <div className={this.state.activeIndex !== 16 ? 'slider__circle' : ' slider__circle--active'}>
                  <div className={this.state.activeIndex !== 16 ? 'slider__circle__inner' : 'slider__circle--active__inner'}>
                    <p>Step 6</p>
                  </div>
                </div>
              </button>
            </div>
            <div>
              <button onClick={(e) => { this.showText(e, sliderContent.step7, 17) }}>
                <div className={this.state.activeIndex !== 17 ? 'slider__circle' : ' slider__circle--active'}>
                  <div className={this.state.activeIndex !== 17 ? 'slider__circle__inner' : 'slider__circle--active__inner'}>
                    <p>Step 7</p>
                  </div>
                </div>
              </button>
            </div>

          </Slider>
        </div>
      </div>
    );
  }
}

